const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../../../src/routes/__error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/datenschutzerklaerung.svelte"),
	() => import("../../../src/routes/impressum.svelte"),
	() => import("../../../src/routes/lernen/__layout.svelte"),
	() => import("../../../src/routes/lernen/index.svelte"),
	() => import("../../../src/routes/lernen/[seminar]/index.svelte"),
	() => import("../../../src/routes/lernen/[seminar]/login.svelte"),
	() => import("../../../src/routes/lernen/[seminar]/[take]/index.svelte"),
	() => import("../../../src/routes/lernen/[seminar]/[take]/[submissionId]/index.svelte"),
	() => import("../../../src/routes/wissen/index.svelte"),
	() => import("../../../src/routes/wissen/methodologie/__layout.svelte"),
	() => import("../../../src/routes/wissen/methodologie/index.svelte"),
	() => import("../../../src/routes/wissen/methodologie/[method].svelte"),
	() => import("../../../src/routes/wissen/mediathek/__layout.svelte"),
	() => import("../../../src/routes/wissen/mediathek/index.svelte"),
	() => import("../../../src/routes/wissen/glossar/__layout.svelte"),
	() => import("../../../src/routes/wissen/glossar/index.svelte"),
	() => import("../../../src/routes/wissen/glossar/[glossaritem].svelte"),
	() => import("../../../src/routes/wohnen/index.svelte"),
	() => import("../../../src/routes/wohnen/[selected].svelte"),
	() => import("../../../src/routes/bug.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/datenschutzerklaerung.svelte
	[/^\/datenschutzerklaerung\/?$/, [c[0], c[3]], [c[1]]],

	// src/routes/impressum.svelte
	[/^\/impressum\/?$/, [c[0], c[4]], [c[1]]],

	// src/routes/lernen/index.svelte
	[/^\/lernen\/?$/, [c[0], c[5], c[6]], [c[1]]],

	// src/routes/lernen/logout.json.ts
	[/^\/lernen\/logout\.json$/],

	// src/routes/lernen/[seminar]/index.svelte
	[/^\/lernen\/([^/]+?)\/?$/, [c[0], c[5], c[7]], [c[1]], (m) => ({ seminar: d(m[1])})],

	// src/routes/lernen/[seminar]/login.json.ts
	[/^\/lernen\/([^/]+?)\/login\.json$/],

	// src/routes/lernen/[seminar]/login.svelte
	[/^\/lernen\/([^/]+?)\/login\/?$/, [c[0], c[5], c[8]], [c[1]], (m) => ({ seminar: d(m[1])})],

	// src/routes/lernen/[seminar]/[take]/index.json.ts
	[/^\/lernen\/([^/]+?)\/([^/]+?)\.json$/],

	// src/routes/lernen/[seminar]/[take]/index.svelte
	[/^\/lernen\/([^/]+?)\/([^/]+?)\/?$/, [c[0], c[5], c[9]], [c[1]], (m) => ({ seminar: d(m[1]), take: d(m[2])})],

	// src/routes/lernen/[seminar]/[take]/[submissionId]/index.json.ts
	[/^\/lernen\/([^/]+?)\/([^/]+?)\/([^/]+?)\.json$/],

	// src/routes/lernen/[seminar]/[take]/[submissionId]/index.svelte
	[/^\/lernen\/([^/]+?)\/([^/]+?)\/([^/]+?)\/?$/, [c[0], c[5], c[10]], [c[1]], (m) => ({ seminar: d(m[1]), take: d(m[2]), submissionId: d(m[3])})],

	// src/routes/wissen/index.svelte
	[/^\/wissen\/?$/, [c[0], c[11]], [c[1]]],

	// src/routes/wissen/methodologie/index.svelte
	[/^\/wissen\/methodologie\/?$/, [c[0], c[12], c[13]], [c[1]]],

	// src/routes/wissen/methodologie/[method].svelte
	[/^\/wissen\/methodologie\/([^/]+?)\/?$/, [c[0], c[12], c[14]], [c[1]], (m) => ({ method: d(m[1])})],

	// src/routes/wissen/mediathek/index.svelte
	[/^\/wissen\/mediathek\/?$/, [c[0], c[15], c[16]], [c[1]]],

	// src/routes/wissen/glossar/index.svelte
	[/^\/wissen\/glossar\/?$/, [c[0], c[17], c[18]], [c[1]]],

	// src/routes/wissen/glossar/[glossaritem].svelte
	[/^\/wissen\/glossar\/([^/]+?)\/?$/, [c[0], c[17], c[19]], [c[1]], (m) => ({ glossaritem: d(m[1])})],

	// src/routes/wohnen/index.svelte
	[/^\/wohnen\/?$/, [c[0], c[20]], [c[1]]],

	// src/routes/wohnen/[selected].svelte
	[/^\/wohnen\/([^/]+?)\/?$/, [c[0], c[21]], [c[1]], (m) => ({ selected: d(m[1])})],

	// src/routes/bug.svelte
	[/^\/bug\/?$/, [c[0], c[22]], [c[1]]]
];

export const fallback = [c[0](), c[1]()];